/* eslint-disable default-case */
import React from "react";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import ListWrapper from "../ListWrapper/ListWrapper";

function AdLogin() {  
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ login, authenticationState, error }) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return <ListWrapper />;
          case AuthenticationState.Unauthenticated:
            return (
              <div>
                {error && (
                  <p>
                    <span>Något gock fel var god försök logga in igen!</span>
                  </p>
                )}
                <p>
                  <button onClick={login}>Login</button>
                </p>
              </div>
            );
          case AuthenticationState.InProgress:
            return <p>Loggar in...</p>;
        }
      }}
    </AzureAD>
  );
}

export default AdLogin;
