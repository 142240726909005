import React from "react";
import ReactDOM from "react-dom";
import './index.css';
import Toplist from "./components/Toplist/Toplist";

ReactDOM.render(
  <React.Fragment>
    <Toplist />
  </React.Fragment>,
  document.getElementById("root")
);
