export const currencyFormat = (value, currency) => {
  let c = currency === "eur" ? "en" : "sv"
  let currencyFractionDigits = new Intl.NumberFormat(c, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).resolvedOptions().maximumFractionDigits;
  
  return value.toLocaleString(c, { maximumFractionDigits: currencyFractionDigits });
};
