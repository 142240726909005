import React, { memo } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import uuid from "uuid";
import { currencyFormat } from "../../helpers/format";

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  names: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  toplist_box: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "20px",
    margin: "5px",
    backgroundColor: "#102b4e",
    color: "#FFF",
    // flex: "0 0 auto"
  },

  table: {
    // width: "50%",
    // justifyContent: "center",
    // width: "50%",
  },
  table_container: {
    // justifyContent: "center",
    // display: "flex",
    // flexDirection : "column"
  },
  text: {
    fontFamily: "Domaine Display",
    fontSize: "16px",
  },
  text_bold: {
    fontFamily: "Domaine Display",
    fontSize: "16px",
  },
  textOffice: {
    fontFamily: "Domaine Display",
    fontSize: "16px",
  },
  textOffice_bold: {
    fontFamily: "Domaine Display",
    fontSize: "16px",
  },
  heading: {
    fontFamily: "Domaine Display",
    fontSize: "16px",
    color: "#FFF",
    backgroundColor: "#102b4e",
    padding: "8px",
  },
  card: {
    margin: "5px",
    // padding : "20px"
  },
  place_cell: {
    paddingRight: "3px",
  },
}));

function ToplistListing({ list, unit, heading, user, customerId, spain }) {
  const classes = useStyles({});
  if (!list) return;

  if (heading === "I min närhet" || heading === "Close to my ranking") {
    let index = list.map((l) => l.userId).indexOf(user.userId);
    if (index < 0) index = list.map((l) => l.customerId).indexOf(customerId);

    if (index > 0) {
      list = list.slice(
        Math.max(index - 2, 0),
        Math.min(index + 3, list.length)
      );
    } else list = null;
  }

  const areEqual = (row) => {
    if (customerId) return row.customerId === customerId;

    return row.userId === user.userId;
  };

  if (!list) return null;

  return (
    <Card className={classes.card}>
      <Box className={classes.heading}>{heading}</Box>
      <TableContainer component={Box} className={classes.table_container}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {list.map((row) => (
              <StyledTableRow key={uuid.v4()}>
                <TableCell component="th" scope="row" width="10px">
                  {row.place}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      className={
                        areEqual(row) ? classes.text_bold : classes.text
                      }
                    >
                      {row.userName}
                    </Box>
                    <Box
                      className={
                        areEqual(row)
                          ? classes.textOffice_bold
                          : classes.textOffice
                      }
                    >
                      {row.customerName}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="right"
                  className={areEqual(row) ? classes.text_bold : classes.text}
                >
                  {unit === "kr" || unit === "EUR" ? (
                    <Box>
                      {currencyFormat(row.value, spain ? "eur" : "sek")} {unit}
                    </Box>
                  ) : (
                    <Box>
                      {row.value} {unit}
                    </Box>
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default memo(ToplistListing);
