import React, { memo, useEffect, useState } from "react";
import { Select, FormControl } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import { fetchDistricts } from "../../service/service";
import uuid from "uuid";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "Domaine Display",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#9a6d4c",
      boxShadow: "0 0 0 3.2px rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    color: "#102b4e",
    fontFamily: "Domaine Display",
    fontSize: "20px",
  },
}));

function ToplistDistrictSelect({ districtChanged, user, sweden }) {
  const classes = useStyles();

  const [value, setValue] = React.useState("Alla");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [districts, setDistricts] = useState([{ name: "Alla", offices: [] }]);

  useEffect(() => {
    districtChanged(districts.find((d) => d.name === value));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const theFetch = async () => {
      await fetchDistricts().then((d) => {
        d.result = [{ name: "Alla" }, ...d.result];
        setDistricts(d.result);
      });
    };

    theFetch();
  }, []);

  return (
    <>
      {user.office && user.office.sweden && sweden ? (
        <FormControl
          className={classes.formControl}
          classes={{ root: classes.label_focusNot }}
        >
          <InputLabel
            focused={false}
            className={classes.label}
            htmlFor="region"
          >
            Region
          </InputLabel>
          <Select
            onChange={handleChange}
            native
            value={value}
            input={<BootstrapInput />}
            id="region"
          >
            {districts.map((r) => (
              <option key={uuid.v4()}>{r.name}</option>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
}

export default memo(ToplistDistrictSelect);
