import React, { useState, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardMedia } from '@material-ui/core';
import MyToplistListBox from '../MyToplistListBox/MyToplistListBox';
import { fetchUserInformation, fetchToplist } from '../../service/service';
import ToplistListing from '../ToplistListing/ToplistListing';
import ToplistDistrictSelect from '../ToplistSelects/ToplistDistrictSelect';

const useStyles = makeStyles((theme) => ({
  heading_container: {
    color: '#FFF',
    alignSelf: 'stretch',
    padding: '25px',
  },
  heading: {
    paddingBottom: '3px',
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'Domaine Display',
    fontSize: '32px',
  },
  month_heading: {
    textAlign: 'center',
    fontFamily: 'Domaine Display',
    fontSize: '20px',
  },
  month_description: {
    textAlign: 'center',
    fontFamily: 'Domaine Display',
    fontSize: '17px',
    paddingTop: '4px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '35%',
    },
  },
  root: {
    borderRadius: '6px',
  },
}));

function List({ token }) {
  const classes = useStyles({});
  const [urlParts, setUrlParts] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [list, setList] = useState(null);

  useEffect(() => {
    const fetchList = async () => {
      fetchToplist(
        urlParts.type,
        urlParts.year,
        urlParts.month,
        true,
        token,
        urlParts.salesType,
        null,
        null,
        selectedDistrict.name !== 'Alla' ? selectedDistrict.name : null,
        urlParts.yearTo,
        urlParts.monthTo,
        urlParts.week,
        urlParts.spain,
        urlParts.sweden,
      ).then((res) => {
        if (res.status === 401) window.location.reload(false);
        else setList(res.result);
      });
    };

    if (urlParts !== null && token !== null && selectedDistrict != null) {
      fetchList();
    }
  }, [token, urlParts, selectedDistrict]);

  useEffect(() => {
    const fetchUser = async () => {
      await fetchUserInformation(token).then((usr) => {
        setUser(usr.result);
      });
    };

    if (user === null && token) {
      fetchUser();
    }
  }, [user, token]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    var currentMonth = new Date().getMonth() + 1;
    var currentYear = new Date().getFullYear();

    const year = urlParams.get('year');
    const month = +urlParams.get('month');
    let monthText;

    const dateText = urlParams.get('dateText');

    if (!year && !urlParams.get('month') && !dateText) {
      monthText = urlParams.get('spain') ? 'Top list' : 'Topplistan';
    } else if (dateText) {
      monthText = dateText;
    } else if (
      currentMonth.toString() === urlParams.get('month') &&
      currentYear.toString() === urlParams.get('year')
    ) {
      monthText = urlParams.get('spain')
        ? 'Current month'
        : 'Innevarande månad';
    } else {
      let mo = '';
      if (month === 0) {
        mo = urlParams.get('spain') ? 'Entire' : 'hela';
      } else if (month === 13) {
        mo = urlParams.get('spain') ? 'Spring' : 'vår';
      } else if (month === 14) {
        mo = urlParams.get('spain') ? 'Autumn' : 'höst';
      } else {
        mo = new Intl.DateTimeFormat(urlParams.get('spain') ? 'en' : 'sv', {
          month: 'long',
        }).format(new Date(year, month - 1));
      }
      monthText = `${mo} ${year}`;
    }

    setUrlParts({
      type: urlParams.get('type'),
      year,
      month,
      salesType: urlParams.get('salesType'),
      heading: urlParams.get('heading'),
      unit: urlParams.get('unit'),
      customerId: urlParams.get('customerId'),
      monthText: monthText,
      yearTo: urlParams.get('yearTo'),
      monthTo: urlParams.get('monthTo'),
      week: urlParams.get('week'),
      description: urlParams.get('description'),
      spain: urlParams.get('spain'),
      sweden: urlParams.get('sweden'),
    });
  }, [token]);

  return (
    <Box className={classes.container}>
      {urlParts ? (
        <CardMedia
          className={classes.heading_container}
          image="https://hh-media-test.fra1.digitaloceanspaces.com/widget/bg.jpg"
        >
          <Box className={classes.heading}>
            {urlParts && token ? urlParts.heading : 'Topplistan'}
          </Box>
          <Box className={classes.month_heading}>
            {urlParts && token ? urlParts.monthText : ''}
          </Box>
          <Box className={classes.month_description}>
            {urlParts && token ? urlParts.description : ''}
          </Box>
        </CardMedia>
      ) : null}
      <Box className={classes.subcontainer}>
        {urlParts && token && user ? (
          <>
            <ToplistDistrictSelect
              districtChanged={(value) => setSelectedDistrict(value)}
              token={token}
              user={user}
              sweden={urlParts.sweden}
            />
            <MyToplistListBox
              type={urlParts.type}
              year={urlParts.year}
              month={urlParts.month}
              token={token}
              user={user}
              salesType={urlParts.salesType}
              heading={user.userName}
              placeHeading={urlParts.spain ? 'My value: ' : 'Mitt värde: '}
              unit={urlParts.unit}
              customerId={urlParts.customerId}
              list={list}
              district={
                selectedDistrict && selectedDistrict.name !== 'Alla'
                  ? selectedDistrict.name
                  : null
              }
              yearTo={urlParts.yearTo}
              monthTo={urlParts.monthTo}
              week={urlParts.week}
              spain={urlParts.spain}
              sweden={urlParts.sweden}
            />
            <ToplistListing
              list={list}
              unit={urlParts.unit}
              heading={urlParts.spain ? 'Close to my ranking' : 'I min närhet'}
              user={user}
              customerId={urlParts.customerId}
              spain={urlParts.spain}
            />
            <ToplistListing
              list={list}
              unit={urlParts.unit}
              heading={urlParts.spain ? 'Top list' : 'Placeringar'}
              user={user}
              customerId={urlParts.customerId}
              spain={urlParts.spain}
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
}

export default memo(List);
