import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import List from "../List/List";
import AdLogin from "../AdLogin/AdLogin";
import { isAuthenticated } from "../../service/service";
import "../../index.css";

function Toplist() {
  const [cookies] = useCookies(["topplist-jwt-token"]);
  const [token, setToken] = useState(null);
  const [authState, setAuthState] = useState(null);
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    let c = cookies["topplist-jwt-token"];
    if (c) {
      setToken(c);
      setAuthState("authCookie");
      return;
    }

    setAuthState("adLogin");
  }, [cookies]);

  useEffect(() => {
    const authenticate = async () => {
      await isAuthenticated(token).then(auth => {
        if (auth.status === 401) setAuthState("invalidCookie");
        else setAuthenticated(auth.result);
      });
    };

    if (token !== null) {
      authenticate();
    }
  }, [token]);

  if (authState === "authCookie" && authenticated){
    console.log("cookieLogin")
    return <List token={token} />;
  }
    

  if (authState === "adLogin") return <AdLogin />;

  if (authState === "invalidCookie") return <AdLogin />;

  return null;
}

export default Toplist;
