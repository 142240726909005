import React, { useEffect, useState } from "react";
import { fetchToplist } from "../../service/service";
import { currencyFormat } from "../../helpers/format";
import { CardMedia, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  toplist_box: (props) => ({
    padding: "20px",
    margin: "5px",
    textAlign: "center",
    backgroundColor: "#102b4e",
    color: props.place === 1 ? "#B7B09C" : "#FFF",
    fontWeight: 300,
    flex: "0 0 auto",
    letterSpacing: "0",
  }),
  heading: {
    fontFamily: "Domaine Display",
    fontSize: "23px",
    paddingTop: "28px",
    paddingBottom: "25px",
  },
  place: {
    fontSize: "23px",
    fontFamily: "Domaine Display",
  },
  placeNumber: {
    fontSize: "24px",
    paddingTop: "5px",
    paddingBottom: "23px",
    fontFamily: "Domaine Display",
  },
  placeHeading: {
    fontSize: "16px",
    paddingBottom: "10px",
    fontFamily: "Domaine Display",
  },
  root: {
    borderRadius: "6px",
  },
}));

function MyToplistListBox({
  user,
  type,
  year,
  month,
  token,
  salesType,
  heading,
  placeHeading,
  unit,
  list,
  customerId,
  district,
  yearTo,
  monthTo,
  week,
  spain,
  sweden,
}) {
  const [result, setResult] = useState({ place: 0, value: 0 });
  const classes = useStyles({ place: result.place });

  useEffect(() => {
    const theFetch = async () => {
      await fetchToplist(
        type,
        year,
        month,
        false,
        token,
        salesType,
        customerId ? null : user.userId,
        customerId ? customerId : null,
        district,
        yearTo,
        monthTo,
        week,
        spain,
        sweden
      ).then((res) => {
        if (res.result) setResult(res.result);
        else setResult({ place: 0, value: 0 });
      });
    };

    theFetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, year, month, token, salesType, user, customerId, district]);

  if (!list) return null;

  let valueNext = null;
  let index = null;
  if (customerId) {
    index = list.map((l) => l.customerId).indexOf(customerId);
  } else {
    index = list.map((l) => l.userId).indexOf(user.userId);
  }

  if (index > 0) {
    valueNext = list[index - 1].value - result.value;
    valueNext = Math.round(valueNext * 100) / 100;
  }

  return (
    <CardMedia
      className={classes.toplist_box}
      classes={{ root: classes.root }}
      image="https://hh-media-test.fra1.digitaloceanspaces.com/widget/bg.jpg"
    >
      <Box className={classes.heading}>{heading}</Box>

      <Box>
        <Box className={classes.place}>
          {spain ? "My ranking" : "Min placering"}
        </Box>
        <Box className={classes.placeNumber}>{result.place}</Box>
        <Box className={classes.placeHeading}>
          {placeHeading}
          {unit === "kr" || unit === "EUR"
            ? `${currencyFormat(result.value, spain ? "eur" : "sek")} ${unit}`
            : `${result.value} ${unit}`}
        </Box>
        {valueNext ? (
          <Box className={classes.placeHeading}>
            {"Avstånd till nästa: "}
            {unit === "kr" || unit === "EUR"
              ? `${currencyFormat(valueNext, spain ? "eur" : "sek")} ${unit}`
              : `${valueNext} ${unit}`}
          </Box>
        ) : null}
      </Box>
    </CardMedia>
  );
}

export default MyToplistListBox;
