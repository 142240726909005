import React from "react";
import { getToken } from "../../authProvider";
import List from "../List/List";

function ListWrapper() {  
  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    const getT = async () => {
      return await getToken();
    };
    getT().then(b => {
      setToken(b.idToken.rawIdToken);
    });
  }, []);

  if (!token) return null;
  return <List token={token} />;
}

export default ListWrapper;
