const doFetch = async (url, token) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        console.log('error', res.statusText);
        return { status: res.status, result: null };
      }

      return res.json().then((data) => ({
        status: 200,
        result: data,
      }));
    })
    .catch((error) => console.log(error));
};

const doFetchBlob = async (url, token) => {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        console.log('error', res.statusText);
        return null;
      }

      return res.blob();
    })
    .then((blob) => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = 'provision';
      a.click();
    })
    .catch((error) => console.log(error));
};

export const fetchToplist = async (
  type,
  year,
  month,
  list,
  token,
  salesType = null,
  userId = null,
  customerId = null,
  district = null,
  yearTo = null,
  monthTo = null,
  week = null,
  spain = null,
  sweden = null,
) => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/${type}?list=${list}`;
  month = month.toString();

  if (month && month !== '13' && month !== '14') url = `${url}&month=${month}`;
  if (month === '13') url = `${url}&month=1&monthTo=6`;
  if (month === '14') url = `${url}&month=7&monthTo=12`;
  if (monthTo) url = `${url}&monthTo=${monthTo}`;
  if (yearTo) url = `${url}&yearTo=${yearTo}`;
  if (year) url = `${url}&year=${year}`;
  if (salesType) url = `${url}&salesType=${salesType}`;
  if (userId) url = `${url}&userId=${userId}`;
  if (customerId) url = `${url}&customerId=${customerId}`;
  if (district) url = `${url}&district=${district}`;
  if (week) url = `${url}&week=${week}`;
  if (spain) url = `${url}&spain=${spain}`;
  if (sweden) url = `${url}&sweden=${sweden}`;

  return await doFetch(url, token);
};

export const fetchUserInformation = async (token) => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/user`;
  return await doFetch(url, token);
};

export const isAuthenticated = async (token) => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/user/isAuthenticated`;
  return await doFetch(url, token);
};

export const fetchDistricts = async () => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/districts`;
  return await doFetch(url, null);
};

export const fetchBoosts = async (token) => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/boost`;
  return await doFetch(url, token);
};

export const fetchActiveToplists = async (token) => {
  let url = `${process.env.REACT_APP_SERVICE_URL}/activeToplists`;
  return await doFetch(url, token);
};

export const fetchExcel = async (year, month, token) => {
  console.log('fetchExcel', year, month, token);
  let url = `${process.env.REACT_APP_SERVICE_URL}/sales/commission/${year}/${month}`;
  return await doFetchBlob(url, token);
};
